import "./techSkills.css";

function TechSkills() {
  return (
    <>
      <div className="skills_bg"></div>
      <div className="tech_skills_wrapper">
        <h1>Front-End</h1>
        <div className="skills_card">
          <div className="skills_grid">
            <div className="grid_item">
              <h2>HTML</h2>
            </div>

            <div className="grid_item">
              <h2>CSS</h2>
            </div>

            <div className="grid_item">
              <h2>Javascript</h2>
            </div>

            <div className="grid_item">
              <h2>React</h2>
            </div>

            <div className="grid_item">
              <h2>Tailwind</h2>
            </div>

            <div className="grid_item">
              <h2>Figma</h2>
            </div>
          </div>
        </div>

        {/* Back-End Skills */}
        <h1 className="back-end-title">Back-End</h1>
        <div className="skills_card">
          <div className="skills_grid">
            <div className="grid_item">
              <h2>Node.js</h2>
            </div>

            <div className="grid_item">
              <h2>Express</h2>
            </div>

            <div className="grid_item">
              <h2>MondoDB</h2>
            </div>

            <div className="grid_item">
              <h2>Git</h2>
            </div>

            <div className="grid_item">
              <h2>Firebase</h2>
            </div>

            <div className="grid_item">
              <h2>MySQL</h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TechSkills;
