import "./about.css";

import about_pic_1 from "../../images/profile_pic_1.png"
import about_pic_2 from "../../images/profile_pic_2.png"
import about_pic_4 from "../../images/profile_pic_4.png"




function About() {
    

  return (
    <div className="about_bg_container">
      <div className="about_page">
        <h1 className="page-title">About</h1>

        <div className="about_grid">
          <div className="about_card">
            <p>Hey! I’m Sean and I’m a web developer based in Sydney.</p>

            <br />

            <p>
              I've worked in the tech-support industry for around 8 years now.
              All that experience has given me a really solid understanding of
              the web-development fundamentals, including computer networking,
              cyber security as well as general computer science concepts. I've
              also completed a number of vendor certifications to really
              solidify this knowledge.
            </p>

            <br />

            <p>
              It was during this time that I began to learn about automation and
              scripting using a variety of programming languages. This initial
              interest developed into something I wanted to actively pursue, and
              became the catalyst for my journey into web development.
            </p>

            <br />

            <p>
              My bachelors degree in Computer Animation has also been greatly
              beneficial during this process, as I have been able to leverage my
              understanding of design principles, as well my knowledge in a
              number of image editors to create visually appealing websites and
              applications.
            </p>

            <br />

            <p>
              Outside of work, my main love is rock climbing. It's something
              I've been super passionate about for over 10 years years, and it's
              taken me all around the world, from the UK where I was born, to
              Europe, North America, China, South-East Asia, and eventually
              leading me to where I now live in Sydney.
            </p>

            <p></p>
          </div>

          <div className="about_image_wrapper">
            <img src={about_pic_1} alt="" className="about_pic" />
            <img src={about_pic_4} alt="" className="about_pic" />
            <img src={about_pic_2} alt="" className="about_pic" />
          </div>

        </div>
      </div>
    </div>
  );
}

export default About;
