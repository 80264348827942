import "./home2.css";
import { BsGithub, BsLinkedin } from "react-icons/bs";
import { IoMdDownload } from "react-icons/io";
import { Link } from "react-router-dom";
import pic from "../../images/profile.png";
import useViewport from "../../CustomHooks/UseViewport";
import resume from "../../documents/SeanSmithDev.pdf"

function Home() {
  const { width, breakpoint_ex_small} =
    useViewport();
  return (
    <>
      {/* BACKGROUND IMG */}
      <div className="color_bg">
        {/* Main Content */}
        <div className="home-content-wrapper">
          {/* Image & Title */}
          <div className="img_and_title_wrapper">
            <img src={pic} alt="profile-image" className="profile-image" />
            <h1 className="home-title">Sean Smith</h1>
          </div>
          {/* Sub-heaeder */}
          <div className="subheader-container">
            <h3 className="subheading-text">Full Stack Developer</h3>
          </div>

          {/* Summary */}
          {width > breakpoint_ex_small && (
            <div className="home_summary_wrapper">
              <div className="line"></div>
              <span className="home_summary summary_1">
                A Sydney based web developer,
              </span>
              <span className="home_summary summary_2">
                with over 8 years in the IT industry
              </span>
            </div>
          )}

          {/* Buttons */}
          <div className="button_wrapper">
            <button className="home_buttons">
              <Link to="./projects">Projects</Link>
            </button>
            <button className="home_buttons resume_button">
              Resume{" "}
              <span>
                <a
                    href={resume}
                    download="Sean_Smith_Resume"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="download_link"
                >
                    <IoMdDownload className="download" />
                </a>
              </span>
            </button>
          </div>

          {/* Side Menu */}
          <aside className="socials_container">
            <Link to="https://github.com/seansmith089" target="_blank">
              <BsGithub className="social_logo" style={{ color: "#d6004b" }} />
            </Link>

            <Link
              to="https://www.linkedin.com/in/seansmithtech/"
              target="_blank"
            >
              <BsLinkedin
                className="social_logo"
                style={{ color: "#0077b5" }}
              />
            </Link>
          </aside>
        </div>
      </div>
    </>
  );
}

export default Home;
