import "./contact.css";

import ContactForm from "../../Components/Contact/ContactForm";
import bg from "../../images/about_bg_wide.png";

function Contact() {
  return (

      <div className="contact-page">
        <h1 className="page-title">Contact</h1>
        <ContactForm />
      </div>
    
  );
}

export default Contact;
