import { useState, useEffect } from "react";

const useViewport = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const breakpoint_ex_small = 375;
  const breakpoint_small = 480;
  const breakpoint_medium = 769;
  const breakpoint_large = 1279
  const breakpoint_ex_large = 1919

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return {
    width,
    breakpoint_ex_small,
    breakpoint_small,
    breakpoint_medium,
    breakpoint_large,
    breakpoint_ex_large,
  };
};

export default useViewport;
